import { Link } from "gatsby"
import Image from "gatsby-plugin-sanity-image"
import React, { useState, useEffect, useRef } from "react"
import PortableText from "react-portable-text"

const ProjectTypeLink = ({ item }) => (
  <>
    <li>
      <Link to={`/projects/${item.slug.current}`}>{item.title}</Link>
    </li>
    <li>/</li>
  </>
)

const ProjectBanner = ({
  projectType,
  title,
  _rawExcerpt,
  mainImage,
  open,
  setOpen,
}) => {
  const imageBannerRef = useRef()

  return (
    <div className="grid mb-4 md:grid-cols-12 md:mb-0">
      <div className="flex flex-col order-2 p-6 bg-off-white md:order-1 md:col-span-4 md:p-10">
        <ul className="flex gap-2 text-[14px] leading-[24px] mb-6 md:mb-10">
          <li>
            <Link to="/projects">Our projects</Link>
          </li>
          <li>/</li>
          {projectType.map((item, index) => (
            <ProjectTypeLink item={item} key={index} />
          ))}{" "}
        </ul>
        <div className="mt-auto">
          {title && (
            <h1 className="text-[32px] leading-[1.2] font-serif">{title}</h1>
          )}
          {_rawExcerpt && (
            <PortableText
              className="text-[14px] leading-[24px] text-[#666] my-6 md:my-10"
              content={_rawExcerpt}
              serializers={{
                internalLink: ({ reference, children }) => {
                  const href = `/${reference._type}/${reference.slug.current}`
                  return <Link to={href}>{children}</Link>
                },
                link: ({ href, blank, children }) => {
                  return (
                    <a
                      href={href}
                      target={blank ? "_blank" : "_self"}
                      rel={blank ? "noreferrer" : undefined}
                    >
                      {children}
                    </a>
                  )
                },
              }}
            />
          )}
          {
            <button
              type="button"
              className="link text-[#1B1B1B] text-[14px]"
              open={open}
              onClick={() => setOpen(!open)}
            >
              Enquire now
            </button>
          }
        </div>
      </div>
      <div className="order-1 md:col-span-8 md:order-2">
        {mainImage && mainImage.asset && (
          <Image
            {...mainImage}
            width={1600}
            height={880}
            alt={mainImage.alt}
            className={
              "object-cover object-center h-full aspect-[1087/779] lazy loaded"
            }
          />
        )}
      </div>
    </div>
  )
}

export default ProjectBanner
