import Image from "gatsby-plugin-sanity-image"
import React, { useEffect, useState, useContext } from "react"
import Container from "../Container/Container"
import CatalogueFullWidth from "./CatalogueFullWidth"
import CatalogueText from "./CatalogueText"
import CatalogueThreeQuarter from "./CatalogueThreeQuarter"
import CatalogueTwoPortrait from "./CatalogueTwoPortrait"
import EnquiryBlock from "./EnquiryBlock"
import GlanceBlock from "./GlanceBlock"
import ImageTextBlock from "./ImageTextBlock"
import MediaAwardsBlock from "./MediaAwardsBlock"
import ProjectBanner from "./ProjectBanner"
import ProjectForm from "./ProjectForm"
import RelatedProject from "./RelatedProject"
import RelatedProjects from "./RelatedProjects"
import TimelineBlock from "./TimelineBlock"
import { ProjectEnquiryContext } from "../../context/ProjectEnquiryContext"

import { Link } from "gatsby"
import PortableText from "react-portable-text"
import InView from "../InView"

import { A11y, Autoplay, EffectFade, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css"
import "swiper/css/effect-fade"

import Helmet from "react-helmet"

function ProjectPost(props) {
  const {
    siteSettings: { enquiryCTATitle, enquiryCTAImage, enquiryCTAText },
    title,
    salesforceID,
    mainImage,
    content,
    projectType,
    _rawExcerpt,
    pType,
    simpleContent,
  } = props
  const enquiryContext = useContext(ProjectEnquiryContext)
  const { handleProjectNav, isProjectNavOpened } = enquiryContext
  const [open, setOpen] = useState(false)
  const [muted, setMuted] = useState(true)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    open
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "scroll")
  }, [open])

  const ProjectSlideContent = ({ item }) => (
    <Image
      {...item}
      width={1200}
      height={880}
      className={`lazy ` + (loaded && " loaded")}
      onLoad={() => setLoaded(true)}
      alt=" "
    />
  )

  return (
    <>
      {pType === "full" ? (
        <Container>
          <div className="box-border border-b border-grey-default">
            <ProjectBanner
              projectType={projectType}
              title={title}
              _rawExcerpt={_rawExcerpt}
              mainImage={mainImage}
              open={open}
              setOpen={setOpen}
            />

            <div className="project-sections">
              {content.length > 0 &&
                content.map((content, i) => (
                  <>
                    {content._type === "imageTextBlock" && (
                      <ImageTextBlock
                        content={content}
                        loaded={loaded}
                        muted={muted}
                        setMuted={setMuted}
                        setLoaded={setLoaded}
                      />
                    )}

                    {content._type === "enquiry" && (
                      <EnquiryBlock
                        content={content}
                        enquiryCTAImage={enquiryCTAImage}
                        enquiryCTATitle={enquiryCTATitle}
                        enquiryCTAText={enquiryCTAText}
                        salesforceID={salesforceID}
                        open={open}
                        setOpen={setOpen}
                        loaded={loaded}
                        setLoaded={setLoaded}
                      />
                    )}

                    {content._type === "catalogueFullWidth" && (
                      <CatalogueFullWidth
                        content={content}
                        loaded={loaded}
                        setLoaded={setLoaded}
                        muted={muted}
                        setMuted={setMuted}
                      />
                    )}

                    {content._type === "catalogueThreeQuarter" && (
                      <CatalogueThreeQuarter
                        content={content}
                        loaded={loaded}
                        setLoaded={setLoaded}
                        muted={muted}
                        setMuted={setMuted}
                      />
                    )}

                    {content._type === "catalogueText" && (
                      <CatalogueText content={content} />
                    )}

                    {content._type === "catalogueTwoPortrait" && (
                      <CatalogueTwoPortrait
                        content={content}
                        loaded={loaded}
                        setLoaded={setLoaded}
                      />
                    )}

                    {content._type === "timeline" && (
                      <TimelineBlock content={content} />
                    )}

                    {content._type === "glance" && (
                      <GlanceBlock content={content} />
                    )}

                    {content._type === "mediaAwards" && (
                      <MediaAwardsBlock content={content} />
                    )}

                    {content._type === "relatedProjects" && (
                      <RelatedProjects content={content} />
                    )}
                  </>
                ))}
            </div>
          </div>

          {salesforceID && (
            <>
              <ProjectForm
                open={open || isProjectNavOpened}
                setOpen={() => {
                  if (open) {
                    setOpen()
                  }
                  if (isProjectNavOpened) handleProjectNav()
                }}
                salesforceID={salesforceID}
              />
            </>
          )}
        </Container>
      ) : (
        <>
          <Helmet
            bodyAttributes={{
              class: "no-banner",
            }}
          />
          <Container>
            <InView>
              <div className="container flex flex-col md:grid md:grid-cols-12 mt-[78px] md:mt-[88px] border-b border-grey-default box-border min-h-[70vh]">
                <div className="md:col-span-9 xl:col-span-7 pb-[10px] md:pb-[120px]">
                  <article className="copy md:pr-10">
                    <h1 className="text-[32px] leading-[1.2] font-serif">
                      {title}
                    </h1>
                    {simpleContent.slideshow &&
                    simpleContent.slideshow.images.length > 0 ? (
                      <Swiper
                        modules={[Navigation, Autoplay, A11y, EffectFade]}
                        slidesPerView={1}
                        navigation
                        loop={true}
                        effect={"fade"}
                        speed={1000}
                        autoplay={{
                          delay: 6000,
                          disableOnInteraction: false,
                        }}
                      >
                        {simpleContent.slideshow.images.map((item, index) => (
                          <SwiperSlide key={index}>
                            <ProjectSlideContent item={item} />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    ) : mainImage ? (
                      <Image
                        {...mainImage}
                        width={1200}
                        height={800}
                        alt={mainImage.alt}
                        className={`lazy ` + (loaded && " loaded")}
                        onLoad={() => setLoaded(true)}
                      />
                    ) : null}
                    <div className="flex flex-col md:grid md:grid-cols-12 mt-6 md:mt-[55px]">
                      <div className="md:col-span-9 md:pr-10">
                        {simpleContent._rawBody && (
                          <PortableText
                            className="copy copy--container"
                            content={simpleContent._rawBody}
                            serializers={{
                              internalLink: ({ reference, children }) => {
                                const href = `/${reference._type}/${reference.slug.current}`
                                return <Link to={href}>{children}</Link>
                              },
                              link: ({ href, blank, children }) => {
                                return (
                                  <a
                                    href={href}
                                    target={blank ? "_blank" : "_self"}
                                    rel={blank ? "noreferrer" : undefined}
                                  >
                                    {children}
                                  </a>
                                )
                              },
                            }}
                          />
                        )}
                      </div>
                      <div className="my-4 text-sm md:col-span-3 md:my-0">
                        {simpleContent._rawLocation && (
                          <>
                            <h3 className="text-grey-base">Location</h3>
                            <PortableText
                              content={simpleContent._rawLocation}
                              serializers={{
                                internalLink: ({ reference, children }) => {
                                  const href = `/${reference._type}/${reference.slug.current}`
                                  return <Link to={href}>{children}</Link>
                                },
                                link: ({ href, blank, children }) => {
                                  return (
                                    <a
                                      href={href}
                                      target={blank ? "_blank" : "_self"}
                                      rel={blank ? "noreferrer" : undefined}
                                    >
                                      {children}
                                    </a>
                                  )
                                },
                              }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </article>
                </div>
              </div>
              {simpleContent.related && (
                <>
                  <div className="box-border border-b border-grey-default">
                    <div className="container related-projects">
                      <div className="py-6 md:py-10">
                        {simpleContent.title ? (
                          <h2>{simpleContent.title}</h2>
                        ) : (
                          <h2>More projects</h2>
                        )}
                        {simpleContent.tagline ? (
                          <h3 className="text-[32px] leading-[1.2] font-serif my-6 md:mt-[120px] md:mb-10 max-w-[680px]">
                            {simpleContent.tagline}
                          </h3>
                        ) : (
                          <h3 className="text-[32px] leading-[1.2] font-serif my-6 md:mt-[120px] md:mb-10 max-w-[680px]">
                            Creating places that blur the lines between
                            lifestyle, urban living and design
                          </h3>
                        )}
                        <Link
                          className="mt-2 text-sm link md:mt-auto"
                          to="/projects"
                        >
                          View all projects
                        </Link>
                      </div>
                    </div>
                    <div className="mx-auto w-full max-w-full recent-projects">
                      <Swiper
                        modules={[A11y]}
                        slidesPerView={1.3}
                        speed={1000}
                        breakpoints={{
                          1024: {
                            slidesPerView: 3,
                          },
                        }}
                      >
                        {simpleContent.related.projects.map((item, index) => (
                          <SwiperSlide key={index}>
                            <RelatedProject item={item} key={index} />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                </>
              )}
            </InView>
          </Container>
        </>
      )}
    </>
  )
}

export default ProjectPost
